<template>
  <b-overlay
    variant="white"
    :show="show"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-card>
      <b-row class="mb-2">
        <b-col cols="12" md="8">
          <h4>Mal Kabul</h4>
        </b-col>
        <b-col cols="12" md="4" sm="12" class="text-left"> </b-col>
        <b-col cols="6" md="2" sm="12" class="text-right"> </b-col>
      </b-row>
      <b-row class="pt-2">
        <b-col cols="12" md="4" sm="12">
          <b-row>
            <b-col cols="8" md="8" sm="8">
              <b-form-group
                label-align-sm="left"
                label-for="name-input"
                class="mb-0"
                label="Barcode Ara"
              >
                <b-input-group size="sm">
                  <b-form-input id="filterInput" v-model="searchBarcode" />
                </b-input-group> </b-form-group
            ></b-col>
            <b-col cols="4" md="4" sm="4">
              <b-button
                variant="danger"
                size="sm"
                block
                style="margin-top: 19px"
                @click="searchMethod()"
              >
                Ara
              </b-button>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12" md="8" sm="12">
          <b-row>
            <b-col cols="12" md="8" sm="12">
              <b-form-group label="Ürün Seç" label-for="name-input">
                <v-select
                  placeholder="Ürün Seç"
                  class="select-size-sm"
                  v-model="selectedProduct"
                  :getOptionLabel="
                    (product) => product.barcode + ' - ' + product.name
                  "
                  :reduce="(product) => product.id"
                  :options="allRows"
                  @input="
                    selectedProductMethod(selectedProduct)
                  " /></b-form-group
            ></b-col>
            <b-col cols="4" md="4" sm="4">
              <b-button
                variant="danger"
                size="sm"
                block
                style="margin-top: 19px"
                @click="clear()"
              >
                Temizle
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="pt-2">
        <b-col>
          <b-table
            :striped="false"
            :bordered="false"
            :hover="true"
            :busy="show"
            :items="items"
            :fields="fields"
            :sticky-header="stickyHeader"
            responsive
            :filter="filter"
            class="custom-table"
          >
            <template #cell(quantity)="data">
              <b-form-input
                id="Quantity-input"
                v-model="data.item.quantity"
                type="number"
              />
            </template>
            <template #cell(unitPrice)="data">
              <b-form-input
                id="Price-input"
                v-model="data.item.unitPrice"
                type="number"
              />
            </template>
            <template #cell(actions)="data">
              <b-row>
                <b-col cols="12" md="6">
                  <b-button
                    @click="save(data.item)"
                    size="sm"
                    variant="warning"
                    pill
                    block
                  >
                    <feather-icon icon="SaveIcon" /> Kaydet
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BCard,
  BCardText,
  BLink,
  BTable,
  BButton,
  BOverlay,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BModal,
  VBModal,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BTable,
    BButton,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BModal,
    ToastificationContent,
    BFormCheckbox,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      stickyHeader: false,
      show: false,
      perPage: 8,
      pageOptions: [8, 10, 12],
      currentPage: 1,
      filter: null,
      allRows: [],
      selectedProduct: "",
      items: [],
      fields: [
        { key: "barcode", label: "Barkod", sortable: true },
        { key: "name", label: "Ürün Adı", sortable: true },
        {
          key: "quantity",
          label: "Miktar",
          sortable: true,
          tdClass: "custom-width-quantity",
        },
        {
          key: "unitPrice",
          label: "Alış Fiyatı (TL+KDV Dahil)",
          sortable: true,
          tdClass: "custom-width-unitPrice",
        },
        { key: "actions", label: "Aksiyon" },
      ],

      boxOne: "",
      searchBarcode: "",
    };
  },

  created() {
    this.getProduct();
  },
  methods: {
    async searchMethod() {
      this.items = [];
      for (var i = 0; i < this.allRows.length; i++) {
        if (this.allRows[i].barcode == this.searchBarcode) {
          var pushModel = {
            productId: this.allRows[i].id,
            barcode: this.allRows[i].barcode,
            name: this.allRows[i].name,
            quantity: 0,
            unitPrice: 0,
          };
          this.items.push(pushModel);
        }
      }
      this.searchBarcode = "";
    },
    async save() {
      var satir = this.items[0];
      if (satir.quantity == 0 || satir.quantity == "") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Birim Boş Olamaz..",
          },
        });
        return;
      }
      if (satir.unitPrice == null) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Birim Fiyat Boş Olamaz..",
          },
        });
        return;
      }

      var sendModel = {
        quantity: satir.quantity,
        unitPrice: satir.unitPrice,
        productId: satir.productId,
      };
      this.$http
        .post("buys", sendModel)
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            this.clear();
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    async clear() {
      this.selectedProduct = null;
      this.items = [];
    },
    async selectedProductMethod(data) {
      this.items = [];
      for (var i = 0; i < this.allRows.length; i++) {
        if (this.allRows[i].id == data) {
          var pushModel = {
            productId: this.allRows[i].id,
            barcode: this.allRows[i].barcode,
            name: this.allRows[i].name,
            quantity: 0,
            unitPrice: 0,
          };
          this.items.push(pushModel);
        }
      }
    },
    async getProduct() {
      this.show = true;
      this.$http
        .get("Products")
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            var satirlar = response.data;
            this.allRows = satirlar;
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
  },
};
</script>

<style>
.custom-width-actions {
  width: 180px; /* Set the width of the Actions column */
}
.custom-width-quantity {
  min-width: 180px; /* Set the width of the Actions column */
}
.custom-width-unitPrice {
  min-width: 180px; /* Set the width of the Actions column */
} /* Example media query for smaller screens */
</style>
